import { LiveUpdate } from '@capawesome/capacitor-live-update'

const sync = async () => {
  const result = await LiveUpdate.sync()
  if(result.nextBundleId) {
    await LiveUpdate.reload()
  }
}

const ready = async () => {
  await LiveUpdate.ready()
}


const setChannel = async (channel) => {
  await LiveUpdate.setChannel({ channel: channel })
}


const initAutoUpdate = async (channel=null) => {
  if(Capacitor.getPlatform() !== 'web') {
    
    if(channel){
      setChannel(channel)
    }

    await sync()
    await ready()
  }
}

export default initAutoUpdate