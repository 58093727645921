import axios from 'axios'
import SHA256 from 'crypto-js/sha256'
import store from '@/store'
import router from '@/router'
import logout from '@/shared-modules/logout.js'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Accept'       : 'application/json',
    'Content-Type' : 'application/json',
  },
})

// Flag to indicate whether translations are being fetched
let isFetchingTranslations = false

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    if (store.getters.JWT_TOKEN !== undefined) {
      config.headers.Signature = SHA256(`${process.env.VUE_APP_API_CLIENT_ID}:${process.env.VUE_APP_API_CLIENT_SK}`)
      config.headers.Authorization = `Bearer ${store.getters.JWT_TOKEN}`
      config.headers['Worker-Id'] = store.getters.EMPLOYEE_UUID
    }

    // Check if translations are empty then fetch from server and set
    let translations = store.getters.TRANSLATIONS

    //commented, it's causing the public route to not translate
    
    if (!Object.keys(translations).length && config.url != '/employee/logout' /*&& store.getters.JWT_TOKEN !== undefined*/) {
      if (!isFetchingTranslations) {
        isFetchingTranslations = true
        const lang = store.getters?.EMPLOYEE_DATA?.language?.code?.toLowerCase() ?? 'en'
        try {
          await axiosInstance.get(`employee/translations/${lang}`,{ data:{},}).then(response => {
            translations = response.data.data
            store.commit('SET_TRANSLATIONS', translations)
          })
        } catch (error) {
          console.error('Error fetching translations:', error)
        } finally {
          isFetchingTranslations = false
        }
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);


//send response as it is 
axiosInstance.interceptors.response.use(
response => {
  if(response.data?.worker){
    store.commit('UPDATE_BULK_EMPLOYEE_DATA', response.data.worker)
  }
  if(response.data?.storage_api_token){
    store.commit('UPDATE_EMPLOYEE_DATA', { key: 'storage_api_token', value: response.data.storage_api_token})
  }
  return response
},
error => {
  // Reject promise if usual error
  if(error.response.status !== 401) {
    return Promise.reject(error)
  }

  //handle UnAuthorized Access
  if(error.response.status == 401) {
    if (router.currentRoute.name == 'Login') {
      return Promise.reject(error)
    }
    else{
      return new Promise((resolve,reject)=>{
        if(!error.response.headers['login-disallowed']){//To handle remember me case if user login is disallowed from backend while session is active
          axiosInstance.post('employee/refresh-token',{'old_token' : store.getters.JWT_TOKEN}).then(response => {
            if(response && response.data && response.data.status === 'success') {
  
              store.commit('UPDATE_JWT_TOKEN', response.data.data)//Get new token based on remember me cookie
  
              error.config.headers.Authorization = `Bearer ${store.getters.JWT_TOKEN}`
              resolve(axiosInstance(error.config))
            }
          })
          .catch(e=>{
            reject(error)//if something went wrong while refreshing token
            logout()
          })
        }else{
          logout()
        }
        
      })
    }
  }

})

export default axiosInstance